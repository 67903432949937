/*----------------------------------------*/
/*  09. PRICE CSS START
/*----------------------------------------*/

.price{
    &__inner{
        z-index: 1;
    }
    &__item{
        padding: 23px;
        padding-bottom: 50px;
        @include border-radius(10px);
        border: 2px solid $theme-color;
        @media #{$lg}{
            padding: 60px 20px;
        }
        @media #{$xs}{
            padding: 30px;
        }
        &::after{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: -moz-linear-gradient( -120deg, rgb(105,14,208) 0%, rgb(23,13,162) 100%);
            background-image: -webkit-linear-gradient( -120deg, rgb(105,14,208) 0%, rgb(23,13,162) 100%);
            background-image: -ms-linear-gradient( -120deg, rgb(105,14,208) 0%, rgb(23,13,162) 100%);
            background-image: linear-gradient( -120deg, rgb(105,14,208) 0%, rgb(23,13,162) 100%);
            z-index: -1;
            opacity: 0;
            visibility: hidden;
        }
        & p{
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 42px;
            color: $black;
        }
        & a.price-btn{
            display: inline-block;
            min-height: 50px;
            line-height: 50px;
            text-align: center;
            color: $theme-color;
            background: $grey-4;
            padding: 0 85px;
            min-width: 250px;
            font-size: 15px;
            font-weight: 700;
            @include border-radius(10px);
            &:hover{
                background: $white;
                color: $theme-color;
            }
        }
        &:hover,
        &.active{
            &::after{
                visibility: visible;
                opacity: 1;
            }
            & .price-btn{
                background: $white;
                color: $theme-color;
            }
            & p{
                color: $white;
            }
            & .price__tag{
                & h1{
                    color: $white;
                }
                & span{
                    color: $white;
                }
            }
            & .price__features{
                & ul{
                    & li{
                        & span{
                            color: $white;
                            &::after{
                                background: $white;
                            }
                        }
                    }
                }
            }
            & .price__shape{
                right: -150px;
                visibility: visible;
                opacity: 1;
            }
        }
    }
    &__shape{
        top: -75px;
        right: -100%;
        visibility: hidden;
        opacity: 0;
    }
    &__tag{
        & h1{
            font-size: 74px;
            font-weight: 900;
            margin-bottom: 0px;
            line-height: 1;
        }
    }
    &__features{
        & ul{
            & li{
                margin-bottom: 15px;
                & span{
                    padding-left: 22px;
                    position: relative;
                    &::after{
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 7px;
                        width: 7px;
                        height: 7px;
                        background: $grey-7;
                        @include border-radius(50%);
                    }
                }
            }
        }
    }
    &__tab{
        position: relative;
        & .nav-tabs{
            position: relative;
            width: 220px;
            @include border-radius(6px);
            background: $grey-8;
            padding: 5px;
            border-bottom: 0;
            & .nav-item{
                margin-right: 14px;
                &:last-child{
                    margin-right: 0;
                }
                & .nav-link{
                    padding: 0 25px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                    background: transparent;
                    border: 0;
                    @include border-radius(6px);
                    font-weight: 700;
                    &.active{
                        background: $white;
                        color: $theme-color;
                        @include box-shadow(0px 2px 6px 0px rgba(11, 0, 65, 0.06));
                    }
                }
            }
        }
    }
    &__offer{
        position: absolute;
        top: -50px;
        right: 28%;
        & span{
            display: inline-block;
            height: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 12px;
            padding: 0 15px;
            color: $green;
            background: $green-3;
            font-weight: 700;
            @include border-radius(12px);
        }
        & img{
            position: absolute;
            top: 0;
            right: 98%;
        }
    }
}
.price__tab button:hover{
    color: inherit;
}