article {
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  overflow: hidden;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
  height: 90%;
}
.model-card {
  padding: 2px;
  position: relative;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 15px;
  height: 90%;
  max-width: 300px;
  align-items: center;
  align-self: center;
}

.model-img {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px; /* Set a fixed height for the image container */
}

.model-img img {
  max-width: 100%;
  max-height: 100%; /* Ensure the image doesn't exceed the container's height */
}
  article a::after {
    position: absolute;
    inset-block: 0;
    inset-inline: 0;
    cursor: pointer;
    content: "";
  }
  
  /* basic article elements styling */
  article h2 {
    margin: 0 0 8px 0;
    font-size: 1.9rem;
    letter-spacing: 0.06em;
    /* color: var(--title-color); */
    transition: color 0.3s ease-out;
  }
  
  figure {
    margin: 0;
    padding: 0;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    height: 100%; /* Set a fixed height for the figure */
  }
  
  
  article img {
    max-width: 100%;
    
    transform-origin: center;
    transition: transform 0.4s ease-in-out;
  }
  
  .article-body {
    padding: 8px 24px 20px 24px;
  }
  .article-body2 {
    padding: 8px 12px 12px 12px;
    text-align: center;
  }
  
  article a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #28666e;
  }
  
  article a:focus {
    outline: 1px dotted #28666e;
  }
  
  article a .icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    transform: translateX(var(--link-icon-translate));
    opacity: var(--link-icon-opacity);
    transition: all 0.3s;
  }
  
  /* using the has() relational pseudo selector to update our custom properties */
  article:has(:hover, :focus) {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  
  
  /************************ 
  Generic layout (demo looks)
  **************************/
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  
  .articles {
    display: grid;
    max-width: 1200px;
    margin-inline: auto;
    padding-inline: 24px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 24px;
  }
  
  /* @media screen and (max-width: 960px) {
    article {
      container: card/inline-size;
    }
    .article-body p {
      color:black;
      display: none;
    }
  } */
  
  @container card (min-width: 380px) {
    .article-wrapper {
      display: grid;
      grid-template-columns: 100px 1fr;
      gap: 16px;
    }
    .article-body {
      padding-left: 0;
    }
    figure {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    figure img {
      height: 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }
  
  .sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  }